:root {
  --color-primary: #3445b4;
  /* --color-primary: #086972;  */
}

.colorempresa {
  background: var(--color-primary);
  /* height: 40px; */
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.logomenu {
  max-width: 200px;
  /* max-height: 50px;*/
}

@media (max-width: 768px) {
  .logomenu {
    max-width: 200px;
    /* max-height: 50px; */
  }
}

.micolumna {
  width: 50px;
  overflow: auto;
}

.custom-color {
  background-color: #086972;
  color: white;
}

.easystock-color {
  color: #086972;
}

.headerNav {
  transition: transform 0.3s ease;
}

.update-var {
  background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #ffffff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  position: relative;
  -webkit-background-clip: text;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.update-var:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.update-var:hover {
  background-position: 0;
}

.update-var:hover::before {
  width: 100%;
}

.update-footer {
  background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #ffffff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}

.update-footer:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.update-footer:hover {
  background-position: 0;
}

.update-footer:hover::before {
  width: 100%;
}

.ps-menu-button {
  height: 35px !important;
}

.table-responsive {
  position: relative;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #fff; /* Color de fondo del encabezado */
  font-size: 12px !important;
}

.table tbody tr td {
  font-size: 9px !important;
}

th {
  /* border: 1px solid #ccc; */
  /* padding: 8px; */
  resize: horizontal;
  overflow: auto;
  min-width: 50px;

}

body{
  background-color: #fff !important;
}
