*,
:after,
:before {
  box-sizing: border-box;
}

/* ----------------------------------------------------------------------------------------- */

.login-wrap {
  width: 100%;
  margin: auto;
  /* max-width: 525px; */
  min-height: 95vh;
  position: relative;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.login-html {
  width: 100%;
  height: 93vh;
  position: absolute;
  padding: 40px 40px 50px 10px;
}

/* //---------------------------------------------------- */

.logo {
  width: 230px;
}

.logoHome {
  width: 480px;
  min-width: 200px;
}

@media (max-width: 768px) {
  .logo {
    width: 200px;
  }

  .logoHome {
    width: 300px;
  }
  .logoempresa {
    width: 120px;
  }
  .player {
    height: 100px;
    width: 100px;
  }
  .player2 {
    height: 100px;
    width: 100px;
  }
  .abs-center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
}

.player {
  height: 300px;
  width: 300px;
}
.player2 {
  height: 400px;
  width: 400px;
}

.logoempresa {
  width: 200px;
}

/* //---------------------------------------------------- */

table tbody tr {
  font-size: 11px;
  vertical-align: middle;
}

table thead tr {
  font-size: 13px;
  vertical-align: middle;
}

/* //---------------------------------------------------- */

/* Ajusta la velocidad de todas las animaciones de desvanecimiento */
.fade {
  transition-duration: 0.3s;
  /* Modifica la duración según tus necesidades */
}

/* Ajusta la velocidad de todas las animaciones de colapso */
.collapse {
  transition-duration: 0.3s;
  /* Modifica la duración según tus necesidades */
}

.collapse.show {
  transition-duration: 0.3s;
  /* Modifica la duración según tus necesidades */
}

.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.custom-color {
  background-color: #086972;
  color: white;
}

.easystock-color {
  color: #086972;
}

.custom-color2 {
  background-color: #0b2948 !important;
  color: white !important;
}

.truncate-text:hover {
  white-space: normal;
  word-wrap: break-word;
}

