/********** Template CSS **********/
:root {
  --primary: #086972;
  --secondary: #0ba0ad;
  --light: #f4f7fe;
  --dark: #14183e;
}

body {
  font-family: "Open Sans", sans-serif;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Heading ***/
.h1-easystock,
.h2-easystock,
.h3-easystock,
.fw-bold {
  font-weight: 700 !important;
}

.h4-easystock,
.h5-easystock,
.h6-easystock,
.fw-medium {
  font-weight: 500 !important;
}

/*** Button ***/
.btn-easystock {
  font-weight: 500;
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Navbar ***/
.sticky-top {
  top: -150px;
  transition: 0.5s;
}

.navbar-easystock {
  padding: 11px 0 !important;
  height: 75px;
}

.navbar-easystock .navbar-nav .nav-link {
  margin-right: 35px;
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  outline: none;
}

.navbar-easystock .navbar-nav .nav-link:hover,
.navbar-easystock .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 1);
}

.navbar-easystock .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar-easystock .navbar-nav {
    padding: 0 15px;
    background: var(--primary);
  }

  .navbar-easystock .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }
}

@media (min-width: 992px) {
  .navbar-easystock .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar-easystock .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header ***/

.easystock-header {
  margin-top: -75px;
  background: url("../images/bg.png") center center no-repeat;
  background-size: cover;
}

.easystock-header .breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

/*** Section Title ***/
.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 4px;
  bottom: 0;
  left: 0;
  background: var(--dark);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  bottom: 0;
  left: 50px;
  background: var(--dark);
}

.section-title.text-center::before {
  left: 50%;
  margin-left: -25px;
}

.section-title.text-center::after {
  left: 50%;
  margin-left: 25px;
}

.section-title h6::before,
.section-title h6::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 2px;
  left: 0;
  background: #086972 /*  rgba(33, 66, 177, .5) */;
}

.section-title h6::after {
  top: 5px;
  left: 3px;
}

/*** About ***/
.about-img {
  position: relative;
  overflow: hidden;
}

.about-img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: url(../img/bg-about-img.png) top left no-repeat; */
  background-size: contain;
}

/*** Service ***/
.service-item {
  position: relative;
  padding: 45px 30px;
  background: #ffffff;
  transition: 0.5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item .service-icon {
  margin: 0 auto 20px auto;
  width: 90px;
  height: 90px;
  color: var(--primary);
  background: var(--light);
  transition: 0.5s;
}

.service-item:hover .service-icon {
  background: #ffffff;
}

.service-item h5,
.service-item p {
  transition: 0.5s;
}

.service-item:hover h5,
.service-item:hover p {
  color: #ffffff;
}

.service-item a.btn {
  position: relative;
  display: flex;
  color: var(--primary);
  transition: 0.5s;
  z-index: 1;
}

.service-item:hover a.btn {
  color: var(--primary);
}

.service-item a.btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  border-radius: 35px;
  background: var(--light);
  transition: 0.5s;
  z-index: -1;
}

.service-item:hover a.btn::before {
  width: 100%;
  background: var(--light);
}

/*** Feature ***/
.feature {
  background: url("../images/bg-3.png") center center no-repeat;
  background-size: cover;
}

/*** Newsletter ***/
.newsletter {
  background: url("../images/bg-2.png") center center no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .newsletter .container {
    max-width: 100% !important;
  }

  .newsletter .newsletter-text {
    padding-right: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .newsletter .newsletter-text {
    padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .newsletter .newsletter-text {
    padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** FAQs ***/
.accordion .accordion-item {
  border: none;
  margin-bottom: 15px;
}

.accordion .accordion-button {
  background: var(--light);
  border-radius: 2px;
}

.accordion .accordion-button:not(.collapsed) {
  color: #ffffff;
  background: var(--primary);
  box-shadow: none;
}

/* .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */

.accordion .accordion-body {
  padding: 15px 0 0 0;
}

/*** Footer ***/
.footer {
  background: url("../images/footer.png") center center no-repeat;
  background-size: contain;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(256, 256, 256, 0.1);
  border-radius: 40px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  transition: 0.3s;
}

.footer .btn.btn-link:hover {
  color: #ffffff;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: rgba(255, 255, 255, 0.5);
}

.footer .copyright a:hover {
  color: #ffffff;
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

/* //-------------------------------------------------- */

.bg-easystock {
  background-color: #086972 !important;
}

.btn-easystock {
  background-color: #086972 !important;
}

.btn-outline-easystock {
  color: #086972;
}

.text-easystock {
  color: #086972;
}

.h1-easystock {
  font-size: calc(1.375rem + 1.5vw);
  color: #086972;
}

.h5-easystock {
  font-size: 1.25rem;
  color: #086972;
}

.accordion-easystock {
  font-size: 1rem;
  margin: 0 15px 0 15px;
}

/* //-------------------------------------------------- */

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.slideInRight {
  animation: slideInRight 1s ease-in-out;
}

/* Estilos CSS para la animación fadeIn */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s;
}
