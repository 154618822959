/* Estilo para centrar el loader y añadir un fondo opaco */
.abs-center-loader {
    position: fixed; /* Posición fija respecto al viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
    z-index: 9999; /* Asegura que el modal esté sobre otros elementos */
  }
  
  .blocks-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }